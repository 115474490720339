import * as React from "react";
import {
  Wrapper,
  EventWrapper,
  PdpWrapper,
  DescriptionWrapper,
  DownloaderWrapper,
  PhotosWrapper,
CarrouselWrapper2,
  TitleWrapper,
  ImageWrapper,
  MobileTile,
  GifTile,
  LandingSlider,
  CacheCache,
  VirtualMobile,
  ImageArtistWrapper,
  ArtistBioText,
  ArtistBioWrapper,
  ArtistBio,
  ArtistImage
} from "./CrashExpo.module.css";
import { GrayTitle } from "./../../../../css/Title.module.css";
import Carrousel from "../../../../components/Carrousel";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';

import Tile from "./../../../../res/graffitiDynamics/tile.png";
import InSitu1 from "./../../../../res/crash/in-situ1.png";
import InSitu2 from "./../../../../res/crash/in-situ2.png";
import InSitu3 from "./../../../../res/crash/in-situ3.png";
import CP from "../../../../res/crash/cp.pdf"

import CTILE from "../../../../res/crash/tile.jpg"
import CSLIDE from "../../../../res/crash/slide.jpg"

import InSitu4 from "./../../../../res/crash/crash-1.png";
import InSitu5 from "./../../../../res/crash/crash-2.png";
import InSitu6 from "./../../../../res/crash/crash-3.png";


const isBrowser = typeof window !== "undefined";
const infos ={
  photos: [
    { src: InSitu1, name: "Explosion, 1981", artistes: "© Crash", description: "Spray paint on canvas", photos: "Photo : Ghost Galerie", style2:"Signed and dated in the composition", dimensions: "107 7⁄8 x 57 9/32 in." },
    { src: InSitu2, name: "Computer style Crash, 1983", artistes: "© Crash", description: "Spray paint and felt on canvas", photos: "Photo : Ghost Galerie", style2:"Signed and dated in the composition and on the reverse", dimensions: "33 7⁄8 x 44 1⁄2 in."  },
    { src: InSitu3, name: "Swiish, 1982", artistes: "© Crash", description: "Spray paint on canvas", photos: "Photo : Ghost Galerie",  style2:"Signed, titled and dated “11-29/89” on the reverse", dimensions: "83 7⁄8 x 60 1⁄4 in" },
    // { src: InSitu4, name: "InSitu4", artistes: "© Dondi White, Lady Pink, Daze, Noc 167, Seen", description: "ECHOES OF THE PAST Ghost Galerie, Paris 2023/2024", photos: "Photo : Ghost Galerie" },
    // { src: InSitu5, name: "InSitu5", artistes: "© Bear, Lee Quinones and A-One", description: "ECHOES OF THE PAST Ghost Galerie, Paris 2023/2024", photos: "Photo : Ghost Galerie" },
    // { src: InSitu6, name: "InSitu6", artistes: "© Lee Quinones and Futura", description: "ECHOES OF THE PAST Ghost Galerie, Paris 2023/2024", photos: "Photo : Ghost Galerie" },
  ],
  photos2: [
    { src: InSitu4},
    { src: InSitu5},
    { src: InSitu6},
  ],
};

const CrashExpo = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div>
       <div className={GifTile}>
        <img src={CTILE} style={{backgroundSize:"contain", width: "80vw", paddingTop: "25px"}}></img>
        </div>
        <div className={LandingSlider}>
        <Carousel 
        swipeable={false}
        draggable={false}
        slidesToSlide={1}
        responsive={responsive}
        autoPlay={false}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        partialVisible={false}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        >
      <div style={{height: "100vh",display: "flex", position: "relative",paddingTop: "35px"}}>
        <img src={CSLIDE} style={{backgroundSize:"contain", height: "100vh", width: "100vw"}}></img>
      </div>
    </Carousel>
        </div>
      <div className={Wrapper}>
      <br />
      <div className={CacheCache}>
        <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
          <div style={{margin: "25px"}}>
            <p style={{fontSize: "60px", lineHeight: "77px", fontWeight: "bold"}}>ECHOES OF THE PAST</p>
            <p style={{fontSize: "34px", lineHeight: "43px", marginTop: "-4px"}}>TIMELESS CREATIVITY & EXPLORATION <br/> FROM 1980 TO 1990</p>
            <p style={{fontSize: "16px", lineHeight: "21px", margin: "9px 0 0"}}>John CRASH Matos Retrospective</p>
          </div>
          <div style={{display: "flex"}}>
          <div style={{margin: "25px"}}>
            <p style={{fontSize: "28px", lineHeight: "36px", marginBottom: "30px", fontWeight: "bold"}}>
            March 21st - April 21st 2024
            </p>
            
          </div>
          <div style={{margin: "25px"}}>
            <p style={{fontSize: "28px", lineHeight: "36px", margin: "0 0 19px", fontWeight: "bold"}}>PARIS</p>
            <p style={{fontSize: "16px", lineHeight: "21px"}}>62 RUE DU FAUBOURG <br /> SAINT-HONORÉ</p>
            <p style={{fontSize: "16px", lineHeight: "21px"}}>75008 PARIS FRANCE</p>
          </div>
          </div>
        </div>
        <div style={{display: "flex",width: "50%", paddingBottom: "40px", paddingLeft: "50px"}}>
          {/* <a href="/en/contactez-nous">
            <p style={{textDecoration: "underline", fontWeight: "bold"}}>CATALOGUE SUR DEMANDE</p>
          </a> */}
          <a  href={CP} style={{textDecoration: "none"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>COMMUNIQUÉ DE PRESSE</p></a>
          <a href="/en/contactez-nous" style={{textDecoration: "none", paddingLeft: "10px"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>CONTACT</p></a>
          <a href="/fr/visite-virtuelle-crash" style={{textDecoration: "none", paddingLeft: "10px"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>VISITE VIRTUELLE</p></a>
        </div>
      </div>
        <div className={VirtualMobile}>
          <a href="/fr/visite-virtuelle-crash" style={{textDecoration: "none", paddingLeft: "10px"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>VISITE VIRTUELLE</p></a>
        </div>
        <div className={ArtistBioWrapper}>
          <div className={ArtistImage}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img
              className={ImageArtistWrapper}
              // style={{height: "450px"}}
              src={CTILE}
            />
            </div>
          </div>
          <div className={ArtistBio}>
            <p style={{fontSize: "30px", lineHeight:"38px", margin: "0 0 26px"}}>John CRASH Matos</p>
            {/* <p style={{fontSize: "19px", lineHeight:"32px", fontWeight: "bold"}}>Né en 1998 à Barcelone, Espagne</p> */}
            <p className={ArtistBioText}>
            Né en 1961, John Matos, plus connu sous le nom de CRASH, est originaire du Bronx. En tant que pionnier et figure de proue du mouvement, son parcours artistique audacieux a été essentiel dans la redéfinition des frontières du graffiti New Yorkais et du Post-graffiti...
Nous sommes en 1975. John impose CRASH dans les rues de New York en investissant les wagons de métro et la ville de ses créations colorées. Ces surfaces mobiles sont devenues des toiles éphémères qu’il investit comme un ado rebelle, qui pense et qui affirme que le graffiti n’est pas du vandalisme.
Aux côtés de ses amis, il explora le Wild Style, une déformation artistique du lettrage devenue la signature audacieuse du graffiti new-yorkais des années 1970-1980. Puis il intégra en 1979, le Studio Graffiti qui permit à des artistes - tels que Dondi, Futura, Daze - de se tourner vers la production en atelier. Ce moment marqua le début d'un nouveau chapitre dans sa carrière, embrassant avec passion le mouvement Post-graffiti, mouvement qui allait redéfinir d’une certaine façon la perception de l’art contemporain.
En octobre 1980, âgé de 19 ans, il organise une exposition intitulée “Graffiti Art Success for America” à la Fashion MODA dans le South Bronx. Cette exposition révolutionnaire joua un rôle crucial dans la légitimation du mouvement. Endossant un rôle de pionnier, CRASH élargit considérablement le champ des possibles, faisant le lien entre les artistes de rue et le monde de l’Art de Manhattan, lui permettant ainsi d‘exposer en 1983 dans la prestigieuse galerie de Sidney Janis (Jackson Pollock, Willem de Kooning, Mark Rothko...). Cette exposition marqua un tournant dans sa carrière, lui ouvrant les portes de nombreuses galeries, musées et collections privées prestigieuses, où le nom de CRASH résonne depuis comme un cri de liberté.              </p>
              <br />
            {/* <a style={{color: "#272727", fontSize: "16px", fontWeight: "bold",}} href={CatalogueAna}>Télécharger le catalogue des oeuvres</a> */}
          </div>
        </div>
        </div>
        <div style={{backgroundColor: "#ededed", padding: "50px", marginTop: "50px", display: "flex", flexDirection: "column", marginBottom: "50px"}}>
        
        <p style={{fontSize:"18px", textAlign:"justify", lineHeight: "24px"}}>
        Dans l'éclat insubordonné de l'art contemporain, qui transcende les frontières et invite à la réflexion, la créativité de CRASH résonne comme une mélodie rebelle. Chaque geste devient une note, chaque œuvre une symphonie vivante. Tel un compositeur, il transforme - à partir des années 70 - les rues de New York en une partition visuelle, où le béton et l'acier jouent les supports d'une rébellion stylistique portée par un nouvel instrument : l’aérosol.
Les prémices de son parcours s'enracinent dans l'effervescence exubérante des années 70, parmi les agitations débridées d'un New York underground bouillonnant de vitalité et de génie créatif. De cet univers tumultueux, surgit une poésie urbaine - symphonie graphique rebelle défiant l’ordinaire - portée par des esprits audacieux et des expressions artistiques avant-gardistes. Ils transforment cette période en un creuset d'idées novatrices et de soulèvements, où l'audace et la contestation se conjuguent pour façonner un paysage culturel révolutionnaire. Puis vint la fin de cette époque et le début des années 80 : le
  
passage de la rue à l’atelier. Cette transition fut guidée par le désir d'explorer de nouvelles dimensions éloignées des contraintes de l'éphémère urbain. Il perfectionne sa technique, affine son style et approfondit sa réflexion. Dans cette épopée artistique, cette décennie se dévoile comme une symphonie enivrante, une partition ouverte sur une énergie bouillonnante. Tel un crescendo visuel, les galeries devinrent pour CRASH les vitrines illuminées d'un nouveau monde, énigmatique mais captivant. Son style singulier, fusion harmonieuse de lettrage et de comics, se transforme en une mélodie dansante au rythme de son imagination et de ses inspirations, semblable à un accord d'expressions artistiques en constante évolution. Ses toiles de jeunesse émergent telles des pièces maîtresses, transcendant les codes de l'art contemporain avec audace. Ses œuvres se présentent non seulement comme des créations esthétiques, mais aussi comme des témoignages intrépides d'une expression artistique résolument provocante.
Du dédale urbain aux cimaises des galeries, le parcours artistique de CRASH se dessine peu à peu - où le fugace et la créativité entrelacent leurs destins. Dans l'intimité révélatrice de son atelier, une célébration s'épanouit. Une métamorphose s’opère. Dans ce laboratoire secret, les toiles blanches deviennent des terrains d’expérimentations. Les explosions audacieuses de couleurs, les lettres tracées avec maîtrise, les clins d'œil à la culture pop dessinent un langage visuel orchestré qui capture l'essence intemporelle de la créativité. Invitant à une immersion dans l'imaginaire, ses œuvres incitent à ressentir l'insaisissable, à saisir la spontanéité du geste. L'atelier, en ce sens, n'est pas seulement un lieu physique, mais un portail vers l'univers mental de CRASH. Chaque trait, chaque composition participent à une aventure visuelle où l'artiste, peignant et dessinant l'éphémère dans l'immuable, cristallise l'effervescente essence du graffiti. Ses créations dévoilent des reflets d'émotions et de significations, transcendant l'évanescent pour témoigner de l'audace du Post-graffiti, offrant ainsi une méditation visuelle sur l'éphemérité de l’existence et la permanence de l’art. De l'énergie frénétique des débuts aux explorations plus subtiles de la maturité, il devient pionnier d’une contemporanéité artistique Post-graffiti. Il explore des dimensions plus vastes, remettant en question les normes esthétiques établies et démontrant que la beauté, dans ce contexte, peut revêtir des formes variées et parfois non conventionnelles.
Durant ces 40 ans de création, l'héritage artistique de CRASH perdure, évolue, tissant une chronique visuelle singulière dans la trame intemporelle de l’art. Une empreinte inscrite dans le continuum artistique du temps. Cette exposition dépeint cette conversation artistique où l’aérosol transcende le simple medium pour se muer en force vitale. De la spontanéité audacieuse aux nuances réfléchies, CRASH devient un narrateur visuel, tissant son histoire à travers une palette artistique riche en échos des époques traversées.

        <br />
       </p>
        <br />
        </div>
    <div className={Wrapper}>
    
      <br />
        <div className={PhotosWrapper}>
          <Title title={"Sélection"} />
          <div className={CarrouselWrapper2}>
          <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
              <div>

              <img
              className={ImageWrapper}
              key={image.name}
              alt={image.name}
              src={image.src}
              />
               <div  style={{display: "flex",  alignItems: "start",flexDirection: "column", padding:"10px", width: "240px"}}>
              <strong>{image.name}</strong>
              <p>{image.description}</p>
              <i>{image.artistes}</i>
              <i>{image.style2}</i>
              <p>{image.photos}</p>
              </div> 
              
              </div>
             
              ))}
          </Carousel>
          </div>
          <div className={CarrouselWrapper2}>
          <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos2.map((image) => (
              <div>

              <img
              className={ImageWrapper}
              // key={image.name}
              // alt={image.name}
              src={image.src}
              />
               <div  style={{display: "flex",  alignItems: "start",flexDirection: "column", padding:"10px", width: "240px"}}>
              </div> 
              
              </div>
             
              ))}
          </Carousel>
          </div>
        </div>
        <br />
    </div>
              </div>
  );
};

export default CrashExpo;
